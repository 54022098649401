<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <!-- <div class="card">
          <div class="card-body"> -->
            <div>
              <!-- Filter -->
              <div class="row justify-content-end mt-n3">
                <!-- <div class="col-md-4">
                  <treeselect
                    v-model="filter.posyandu_id"
                    :multiple="false"
                    placeholder="Pilih Posyandu"
                    :options="posyandu_list"
                    @input="filterByPosyandu"
                  />
                </div> -->
                <div class="col-md-4">
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text squared>
                        <b-icon-search></b-icon-search>
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      type="text"
                      v-model="filter.name"
                      placeholder="Saring Nama atau NIK"
                      @keyup="filterByName"
                    ></b-form-input>
                  </b-input-group>
                </div>
              </div>
              <b-table
                striped
                hover
                :items="items"
                :fields="fields"
                class="mt-3"
                responsive
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'action' ? '25%' : '' }"
                  />
                </template>

                <template #cell(action)="data">
                  <template v-if="purpose != 'modal'">
                    <b-button
                      size="sm"
                      class="mr-1 btn-info"
                      v-b-tooltip.hover
                      title="Detail"
                      @click="
                        $router.push({
                          path: '/toddlers/detail/' + data.item.id,
                        })
                      "
                      ><i class="fas fa-eye px-0"></i
                    ></b-button>
                    <b-button
                      size="sm"
                      class="mr-1 btn-success"
                      v-b-tooltip.hover
                      title="Edit"
                      @click="
                        $router.push({
                          path: '/toddlers/edit/' + data.item.id,
                        })
                      "
                      v-if="btnAccess"
                      ><i class="fas fa-edit px-0"></i
                    ></b-button>
                    <b-button
                      size="sm"
                      class="btn-danger"
                      v-b-tooltip.hover
                      title="Hapus"
                      @click="deleteData(data.item.id)"
                      v-if="btnAccess"
                      ><i class="fas fa-trash px-0"></i
                    ></b-button>
                  </template>
                  <template v-if="purpose == 'modal'">
                    <b-button
                      size="sm"
                      variant="primary"
                      v-b-tooltip.hover
                      title="Pilih"
                      @click="
                        chooseToddler({ id: data.item.id, name: data.item.toddler_name })
                      "
                      v-if="purpose == 'modal'"
                      >Pilih</b-button
                    >
                  </template>
                </template>
              </b-table>

              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </div>
          <!-- </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
export default {
  props: {
    purpose: String,
  },
  data() {
    return {
      // filter
      filter: {
        name: "",
        registration_number: "",
        posyandu_id: getUser().posyandu_id
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        // {
        //   key: "posyandu_name",
        //   label: "Posyandu",
        //   sortable: true,
        // },
        {
          key: "registration_number",
          label: "NIK",
          sortable: true,
        },
        {
          key: "toddler_name",
          label: "Nama Balita",
          sortable: true,
        },
        {
          key: "mother_registration_number",
          label: "NIK Ibu",
          sortable: true,
        },
        {
          key: "mother_name",
          label: "Nama Ibu",
          sortable: true,
        },
        {
          key: "father_name",
          label: "Nama Ayah",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      items: [],
      posyandu_list: [],
      // access
      btnAccess: true,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&posyandu_id=${this.filter.posyandu_id}&name=${this.filter.name}&registration_number=${this.filter.registration_number}`;
      let response = await module.paginate(
        "api/toddlers",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    filterByName() {
      this.pagination();
    },
    filterByRegistrationNumber() {
      this.pagination();
    },
    filterByPosyandu(evt) {
      if(typeof evt != 'undefined'){
        this.filter.posyandu_id = evt
        this.pagination()
      }else{
        this.filter.posyandu_id = ''
        this.pagination()
      }
    },

    chooseToddler(value) {
      this.$emit("chooseToddler", value);
    },
  },
  mounted() {
    this.pagination();
  },
};
</script>

<style>
</style>