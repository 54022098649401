<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <!-- <b-form-group
            id="input-group-posyandu"
            label="Posyandu:"
            label-for="input-posyandu"
          >
            <treeselect
              v-model="form.posyandu_id"
              :multiple="false"
              placeholder="Pilih Posyandu"
              :options="posyandu_list"
            />
            <small class="text-danger">{{ error.posyandu_id }}</small>
          </b-form-group> -->

          <b-form-group
            id="input-group-staff"
            label="Petugas Posyandu:"
            label-for="input-staff"
          >
            <treeselect
              v-model="form.staff_id"
              :multiple="false"
              placeholder="Pilih Petugas Posyandu"
              :options="staffs"
            />
            <small class="text-danger">{{ error.staff_id }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-weighing-date"
            label="Tanggal Penimbangan:"
            label-for="input-weighing-date"
          >
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  id="input-weighing-date"
                  v-model="form.weighing_date"
                  label="Tanggal Penimbangan:"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></b-form-input>
              </template>
              <v-date-picker
                v-model="form.weighing_date"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
            <small class="text-danger">{{ error.weighing_date }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-trigger-modal-toddler"
            label="Pilih Balita:"
            label-for="input-trigger-modal-toddler"
          >
            <b-form-input
              id="input-trigger-modal-toddler"
              v-model="display.toddler_name"
              placeholder="Pilih Balita"
              readonly
              @click="$bvModal.show('modal-toddler')"
            >
            </b-form-input>
            <small class="text-danger">{{ error.toddler_id }}</small>
          </b-form-group>

          <!-- <b-form-group
            id="input-group-toddler-id"
            label="Balita:"
            label-for="input-toddler-id"
          >
            <treeselect
              v-model="form.toddler_id"
              :multiple="false"
              placeholder="Pilih Balita"
              :options="toddlers"
            />
            <small class="text-danger">{{ error.toddler_id }}</small>
          </b-form-group> -->

          <!-- <b-form-group
          id="input-group-registration-number"
          label="NIK Balita:"
          label-for="input-registration-number"
        >
          <b-form-input
            id="input-registration-number"
            v-model="form.registration_number"
            placeholder="NIK Balita"
          ></b-form-input>
          <small class="text-danger">{{ error.registration_number }}</small>
        </b-form-group> -->

          <b-form-group
            id="input-group-weight"
            label="Berat Badan (Kg):"
            label-for="input-weight"
          >
            <b-form-input
              id="input-weight"
              v-model="form.weight"
              placeholder="Berat Badan"
            ></b-form-input>
            <small class="text-danger">{{ error.weight }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-height"
            label="Panjang Badan (Cm):"
            label-for="input-height"
          >
            <b-form-input
              id="input-height"
              v-model="form.height"
              placeholder="Panjang Badan "
            ></b-form-input>
            <small class="text-danger">{{ error.height }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-head-circumference"
            label="Lingkar Kepala (Cm):"
            label-for="input-head-circumference"
          >
            <b-form-input
              id="input-head-circumference"
              v-model="form.head_circumference"
              placeholder="Lingkar Kepala "
            ></b-form-input>
            <small class="text-danger">{{ error.head_circumference }}</small>
          </b-form-group>

          <!-- <b-form-group
            id="input-group-p-11"
            label="Dibawah Garis Merah:"
            label-for="input-p-11"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="input-group-p-11"
              v-model="form.p11"
              :aria-describedby="ariaDescribedby"
              name="input-group-p-11"
            >
              <b-form-radio value="1">Ya</b-form-radio>
              <b-form-radio value="0">Tidak</b-form-radio>
            </b-form-radio-group>
            <small class="text-danger">{{ error.p11 }}</small>
          </b-form-group> -->

          <b-form-group
            id="input-group-is-vitamin"
            label="Pemberian Vitamin:"
            label-for="input-is-vitamin"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="input-group-is-vitamin"
              v-model="form.is_vitamin"
              :aria-describedby="ariaDescribedby"
              name="input-group-is-vitamin"
            >
              <b-form-radio value="1">Ya</b-form-radio>
              <b-form-radio value="0">Tidak</b-form-radio>
            </b-form-radio-group>
            <small class="text-danger">{{ error.is_vitamin }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-vitamin-list"
            label="Vitamin:"
            label-for="input-vitamin-list"
            v-if="form.is_vitamin == 1"
          >
            <treeselect
              @select="setVitamin"
              @deselect="removeVitamin"
              v-model="vitamin_list"
              :multiple="true"
              placeholder="Pilih Vitamin"
              :options="vitamin_types"
            />
            <small class="text-danger">{{ error.vitamin_list }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-is-immunization"
            label="Pemberian Imunisasi:"
            label-for="input-is-immunization"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="input-group-is-immunization"
              v-model="form.is_immunization"
              :aria-describedby="ariaDescribedby"
              name="input-group-is-immunization"
            >
              <b-form-radio value="1">Ya</b-form-radio>
              <b-form-radio value="0">Tidak</b-form-radio>
            </b-form-radio-group>
            <small class="text-danger">{{ error.is_immunization }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-immunization-list"
            label="Imunisasi:"
            label-for="input-immunization-list"
            v-if="form.is_immunization == 1"
          >
            <treeselect
              @select="setImmunization"
              @deselect="removeImmunization"
              v-model="immunization_list"
              :multiple="true"
              placeholder="Pilih Imunisasi"
              :options="immunization_types"
            />
            <small class="text-danger">{{ error.immunization_list }}</small>
          </b-form-group>

          <b-form-group id="input-group-description">
            <label for="input-description"
              >Keterangan: <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="input-description"
              v-model="form.description"
              placeholder="Keterangan"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.description }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-exclusive-breastfeeding"
            label="Mendapat ASI Ekslusif:"
            label-for="input-exclusive-breastfeeding"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="input-group-exclusive-breastfeeding"
              v-model="form.is_exclusive_breastfeeding"
              :aria-describedby="ariaDescribedby"
              name="input-group-exclusive-breastfeeding"
            >
              <b-form-radio value="1">Ya</b-form-radio>
              <b-form-radio value="0">Tidak</b-form-radio>
            </b-form-radio-group>
            <small class="text-danger">{{
              error.is_exclusive_breastfeeding
            }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-subtitute-breastfeeding"
            label="Mendapat MPASI:"
            label-for="input-subtitute-breastfeeding"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="input-group-subtitute-breastfeeding"
              v-model="form.is_breast_milk_substitute"
              :aria-describedby="ariaDescribedby"
              name="input-group-subtitute-breastfeeding"
            >
              <b-form-radio value="1">Ya</b-form-radio>
              <b-form-radio value="0">Tidak</b-form-radio>
            </b-form-radio-group>
            <small class="text-danger">{{
              error.is_breast_milk_substitute
            }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-is-pmt"
            label="Mendapat PMT:"
            label-for="input-is-pmt"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="input-group-is-pmt"
              v-model="form.is_pmt"
              :aria-describedby="ariaDescribedby"
              name="input-group-is-pmt"
            >
              <b-form-radio value="1">Ya</b-form-radio>
              <b-form-radio value="0">Tidak</b-form-radio>
            </b-form-radio-group>
            <small class="text-danger">{{
              error.is_pmt
            }}</small>
          </b-form-group>
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push('/weighing-notes')"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>

    <b-modal id="modal-toddler" size="xl" title="Pilih Bayi" hide-footer>
      <Table purpose="modal" @chooseToddler="chooseToddler" />
    </b-modal>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ApiService from "@/core/services/api.service.js";
import Table from "@/view/components/toddler/Table.vue";
import { getUser, setUser } from "@/core/services/jwt.service.js";
// import the component
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
  },
  components: {
    Table,
  },
  data() {
    return {
      error: {
        posyandu_id: "",
        toddler_id: "",
        registration_number: "",
        weight: "",
        height: "",
        head_circumference: "",
        weighing_date: "",
        is_vitamin: "",
        vitamin_list: "",
        is_immunization: "",
        immunization_list: "",
        staff_id: "",
        p11: "",
        description: "",
        is_exclusive_breastfeeding: "",
        is_breast_milk_substitute: "",
        is_pmt: "",
      },
      display:{
        toddler_name: ""
      },
      // input vitamin_list
      vitamin_types: [],
      vitamin_list: [],
      coll_vitamin_form: {
        id: "",
        name: "",
      },
      coll_vitamin: [],
      coll_vitamin_actv: [],
      // input immunization_list
      immunization_types: [],
      immunization_list: [],
      coll_immunization_form: {
        id: "",
        name: "",
      },
      coll_immunization: [],
      // other
      menu2: false,
      posyandu_list: [],
      toddlers: [],
      staffs: [],
    };
  },
  methods: {
    getSelectOptionVitamin(form) {
      if(form.vitamin_list != ""){
        // set vitamin selected
        this.coll_vitamin = JSON.parse(form.vitamin_list);
        let vitamin_actv = [];
        for (let a = 0; a < this.coll_vitamin.length; a++) {
          vitamin_actv.push(this.coll_vitamin[a].id);
          if (a + 1 == this.coll_vitamin.length) {
            this.vitamin_list = vitamin_actv;
          }
        }
      }
    },

    getSelectOptionImmunization(form){
      if(form.immunization_list != ""){
        // set immunization selected
        this.coll_immunization = JSON.parse(form.immunization_list);
        let immunization_actv = [];
        for (let a = 0; a < this.coll_immunization.length; a++) {
          immunization_actv.push(this.coll_immunization[a].id);
          if (a + 1 == this.coll_immunization.length) {
            this.immunization_list = immunization_actv;
          }
        }
      }
    },

    async getToddlerOption() {
      let response = await module.setTreeSelectToddler("api/toddlers");
      console.log("balita", response);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.toddlers = response.data;
        this.toddlers.unshift({
          label: "Pilih Balita",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getPosyanduOption() {
      let response = await module.setTreeSelect("api/posyandu");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.posyandu_list = response.data;
        this.posyandu_list.unshift({
          label: "Pilih Posyandu",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getStaffOption() {
      let response = await module.setTreeSelect(`api/cadres?posyandu_id=${getUser().posyandu_id}`);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.staffs = response.data;
        this.staffs.unshift({
          label: "Pilih Petugas Posyandu",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getVitaminOption() {
      let response = await module.setTreeSelect("api/vitamin-types");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.vitamin_types = response.data;
      }
    },

    async getImmunizationOption() {
      let response = await module.setTreeSelect("api/immunization-types", '?is_general=1');
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.immunization_types = response.data;
      }
    },

    setVitamin(evt) {
      console.log("evt", evt);
      this.coll_vitamin_form.id = evt.id;
      this.coll_vitamin_form.name = evt.label;

      let clone = { ...this.coll_vitamin_form };
      this.coll_vitamin.push(clone);
      console.log("coll_vitamin", this.coll_vitamin);
    },

    removeVitamin(event) {
      console.log(event);
      console.log(event.id);

      let a;
      for (a = 0; a < this.coll_vitamin.length; a++) {
        if (event.id == this.coll_vitamin[a].id) {
          this.coll_vitamin.splice(a, 1);
          console.log("hapus", this.coll_vitamin);
        }
      }
    },

    setImmunization(evt) {
      console.log("evt", evt);
      this.coll_immunization_form.id = evt.id;
      this.coll_immunization_form.name = evt.label;

      let clone = { ...this.coll_immunization_form };
      this.coll_immunization.push(clone);
      console.log("coll_immunization", this.coll_immunization);
    },

    removeImmunization(event) {
      console.log(event);
      console.log(event.id);

      let a;
      for (a = 0; a < this.coll_immunization.length; a++) {
        if (event.id == this.coll_immunization[a].id) {
          this.coll_immunization.splice(a, 1);
          console.log("hapus", this.coll_immunization);
        }
      }
    },

    chooseToddler(value) {
      this.form.toddler_id = value.id;
      this.display.toddler_name = value.name;
      console.log("form", this.form);
      this.$bvModal.hide("modal-toddler");
    },

    async formOnsubmit() {
      if (this.form.is_vitamin == 1) {
        this.form.vitamin_list = JSON.stringify(this.coll_vitamin);
      } else {
        this.form.vitamin_list = "";
      }
      if (this.form.is_immunization == 1) {
        this.form.immunization_list = JSON.stringify(this.coll_immunization);
      } else {
        this.form.immunization_list = "";
      }
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/weighing-notes");
      }
    },
  },
  mounted() {
    this.getToddlerOption();
    this.getVitaminOption();
    this.getImmunizationOption();
    this.getPosyanduOption();
    this.getStaffOption();
  },
  watch: {
    form: function (newVal, oldVal) {
      this.display.toddler_name = this.form.toddler_name
      this.getSelectOptionVitamin(this.form);
      this.getSelectOptionImmunization(this.form);

      console.log('form watch', this.form)
    },
  },
};
</script>